<template>
  <div class="py-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
    <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">Changelog</h1>
  </div>
  <main>
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <h4 class="text-lg font-bold mt-10">26th January 2021</h4>
      <p class="mt-1">
      <ul>
        <li><strong>Fix</strong> - unable to save block reference when Block was created from Block template.</li>
        <li><strong>Fix</strong> - first time visit no vuex store user looks like logged in.</li>
      </ul>
      </p>
      <h4 class="text-lg font-bold mt-10">25th January 2021</h4>
      <p class="mt-1">
      <ul>
        <li><strong>Feat</strong> - User login/sign-up (beta only).</li>
        <li><strong>Feat</strong> - Store data in Fauna DB.</li>
        <li><strong>Feat</strong> - Header navigation.</li>
        <li><strong>Feat</strong> - Reports - basic scaffolding.</li>
      </ul>
      </p>
      <h4 class="text-lg font-bold mt-10">17th January 2021</h4>
      <p class="mt-1">
      <ul>
        <li><strong>Feat</strong> - Better nav with logo and features explored widget (not functional yet).</li>
        <li><strong>Feat</strong> - Include info heart in the nav to link to discord.</li>
        <li><strong>Feat</strong> - Block edit form on enter close modal.</li>
        <li><strong>Feat</strong> - templates placeholders.</li>
        <li><strong>Feat</strong> - blocks placeholders.</li>
        <li><strong>Feat</strong> - favicon colours changes based on the colour of the active block.</li>
      </ul>
      </p>
      <h4 class="text-lg font-bold mt-10">16th January 2021</h4>
      <p class="mt-1">
      <ul>
        <li><strong>Fix</strong> - minor changes to the design and particles.</li>
        <li><strong>Fix</strong> - allow only one timer at a time.</li>
        <li><strong>Fix</strong> - total time reset all not working.</li>
        <li><strong>Fix</strong> - total time display days if more than 24 hours.</li>
        <li><strong>Fix</strong> - total time improve granularity.</li>
        <li><strong>Feat</strong> - apply limits to number of templates and number of blocks for free (4)</li>
        <li><strong>Fix</strong> - remove Add and Remove time for later version. Needs a bit more thinking.</li>
        <li><strong>Fix</strong> - small design change for 1hr tpl</li>
      </ul>
      </p>
      <h4 class="text-lg font-bold mt-10">15th January 2021</h4>
      <p class="mt-1">
        <ul>
          <li><strong>Feat</strong> - Add total time.</li>
          <li><strong>Feat</strong> - Add image for each block.</li>
          <li><strong>Fix</strong> - Remove images as a feature for later version.</li>
          <li><strong>Fix</strong> - Remove Notes as a feature for later version.</li>
          <li><strong>Fix</strong> - Timer refactoring.</li>
          <li><strong>Fix</strong> - "Reset all" button improvement - deletes all properly.</li>
          <li><strong>Fix</strong> - Image not skipped if missing.</li>
          <li><strong>Fix</strong> - Favicon fix on reload.</li>
          <li><strong>Fix</strong> - Calculate overtime and highglight at the bottom in black.</li>
          <li><strong>Fix</strong> - Top nav and templates nav clean up.</li>
          <li><strong>Fix</strong> - Look and feel improvements.</li>
          <li><strong>Fix</strong> - Initial empty state with simple hint and default block set to 4 hours.</li>
          <li><strong>Fix</strong> - Block edit toggle is on title too with cursor pointer.</li>
        </ul>
      </p>
      <h4 class="text-lg font-bold mt-10">12th January 2021</h4>
      <p class="mt-1">
      <ul>
        <li><strong>Fix</strong> - when deleting a template, it deletes the last in the list.</li>
        <li><strong>Fix</strong> - "Reset all" button is not working.</li>
        <li><strong>Fix</strong> - status favicon not always reflects the current state.</li>
      </ul>
      </p>
      <h4 class="text-lg font-bold mt-10">11th January 2021</h4>
      <p class="mt-1">
      <ul>
        <li><strong>Notes</strong> - a simple way to add notes throughout the day</li>
      </ul>
      </p>
      <h4 class="mt-10 text-lg font-bold">10th January 2021</h4>
      <p class="mt-1">
        <ul>
          <li><strong>Permanent local storage</strong> - your blocks will be stored in your local storage, until you clear your browser's local storage</li>
          <li><strong>Block Templates</strong> - Save your blocks as Templates, quickly re-use them afterwards</li>
          <li><strong>Nicer block edit form</strong> - Added color palette, improved formatting</li>
        </ul>
      </p>
      <h4 class="mt-10 text-lg font-bold">5th January 2021</h4>
      <p class="mt-1">
      <ul>
        <li><strong>Particles when block is active</strong></li>
        <li><strong>Elements and elements indicator</strong></li>
      </ul>
      </p>
      <h4 class="mt-10 text-lg font-bold">4th January 2021</h4>
      <p class="mt-1">
      <ul>
        <li><strong>Favicon changes based on the tracker status</strong></li>
      </ul>
      </p>
      <h4 class="mt-10 text-lg font-bold">3rd January 2021</h4>
      <p class="mt-1">
      <ul>
        <li><strong>Color palettes</strong></li>
      </ul>
      </p>
      <h4 class="mt-10 text-lg font-bold">2nd January 2021</h4>
      <p class="mt-1">
      <ul>
        <li><strong>Initial commit, project setup</strong></li>
        <li><strong>Basic block with edit overlay upon click on time</strong></li>
      </ul>
      </p>
      <h4 class="mt-10 text-lg font-bold">Beginning of our Changelog...</h4>
    </div>
  </main>
</template>
